/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { CustomerService } from '@/components/content/CustomerService';
import { GlobalSpinner } from '@/components/content/GlobalSpinner';
import { Notifications } from '@/components/content/Notifications';
import { SessionErrorDialog } from '@/components/content/SessionErrorDialog';
import { layoutManifest } from '@/components/layouts/manifest';
import { Meta } from '@/data/Meta';
import { useStaticSettings } from '@/data/Settings';
import { SettingProvider } from '@/data/context/setting';
import { Layout } from '@/data/types/Layout';
import { ThemeSettingsProvider } from '@/styles/theme';
import { CssBaseline, SxProps, Theme, ThemeProvider } from '@mui/material';
import Head from 'next/head';
import { FC } from 'react';

type PageProps = {
	meta: Meta;
	layout: Layout;
	theme: Theme;
	additives?: { [key: string]: SxProps | ((theme: Theme) => SxProps) };
	storeName?: string;
};

// eslint-disable-next-line complexity
export const PageBlock: FC<PageProps> = ({ meta, layout, theme, additives, storeName }) => {
	const Layout = layout && layoutManifest[layout.name] ? layoutManifest[layout.name] : () => null;

	return (
		<>
			<Head>
				<title>{meta?.title}</title>
				<meta name="description" content={meta?.description} />
				<meta name="keywords" content={meta?.keywords} />
				<meta name="theme-color" content="#000000" />
				<link
					rel="icon"
					type="image/x-icon"
					href={`https://www.wacoal-america.com/wcsstore/WacoalStorefrontAssetStore/upload/img/favicon_${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}.ico`}
				/>
				<link
					rel="shortcut icon"
					href={`https://www.wacoal-america.com/wcsstore/WacoalStorefrontAssetStore/upload/img/favicon_${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}.ico`}
				/>
				<link
					rel="icon"
					type="image/png"
					href={`https://images.wacoal-america.com/is/image/WacoalAmerica/${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}-16`}
					sizes="16x16"
				/>
				<link
					rel="icon"
					type="image/png"
					href={`https://images.wacoal-america.com/is/image/WacoalAmerica/${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}-32`}
					sizes="32x32"
				/>
				<link
					rel="icon"
					type="image/png"
					href={`https://images.wacoal-america.com/is/image/WacoalAmerica/${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}-48`}
					sizes="48x48"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`https://images.wacoal-america.com/is/image/WacoalAmerica/${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}-60`}
					sizes="60x60"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`https://images.wacoal-america.com/is/image/WacoalAmerica/${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}-72`}
					sizes="72x72"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`https://images.wacoal-america.com/is/image/WacoalAmerica/${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}-76`}
					sizes="76x76"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`https://images.wacoal-america.com/is/image/WacoalAmerica/${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}-114`}
					sizes="114x114"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`https://images.wacoal-america.com/is/image/WacoalAmerica/${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}-120`}
					sizes="120x120"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`https://images.wacoal-america.com/is/image/WacoalAmerica/${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}-144`}
					sizes="144x144"
				/>
				<link
					rel="apple-touch-icon-precomposed"
					href={`https://images.wacoal-america.com/is/image/WacoalAmerica/${
						storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
					}-152`}
					sizes="152x152"
				/>
				{/* <link
					rel="icon"
					type="image/png"
					href={`https://images.wacoal-america.com/is/image/WacoalAmerica/${storeName === 'Wacoal Canada' ? 'wacoal' : storeName?.toLowerCase()
						}-180`}
					sizes="180x180"
				/>*/}
			</Head>
			<CustomerService />
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<ThemeSettingsProvider value={{ additives }}>
					<Layout layout={layout} />
					<GlobalSpinner />
					<Notifications />
					<SessionErrorDialog />
				</ThemeSettingsProvider>
			</ThemeProvider>
		</>
	);
};

export const StaticPageBlock: FC<PageProps> = ({ meta, layout, theme, additives }) => {
	const { settings } = useStaticSettings();

	return settings ? (
		<SettingProvider value={settings}>
			<PageBlock
				meta={meta}
				layout={layout}
				theme={theme}
				additives={additives}
				storeName={settings.storeName}
			/>
		</SettingProvider>
	) : null;
};
