/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { productDetailLeftSX } from '@/components/content/WacoalProductPage/styles/ProductDetailLeft';
import { productDetailRightSX } from '@/components/content/WacoalProductPage/styles/ProductDetailRight';
import { ItemWrap } from '@/components/layouts/ItemWrap';
import { LayoutSlot } from '@/components/layouts/LayoutSlot';
import { AccordionSlotsDisplay } from '@/components/layouts/WacoalProductPage/AccordionSlotsDisplay';
import { layoutMainSX } from '@/components/layouts/styles/main';
import { useProductDetails } from '@/data/Content/ProductDetails';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { useSettings } from '@/data/Settings';
import { S7_COMPANY } from '@/data/constants/Scene7';
import { ContentProvider } from '@/data/context/content';
import { useStoreLocatorState } from '@/data/state/useStoreLocatorState';
import { Layout } from '@/data/types/Layout';
import { setSegmentData } from '@/data/utils/setSegmentData';
import { Box, Container, Stack, useTheme } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';

export const WacoalProductPage: FC<{ layout: Layout }> = ({ layout }) => {
	const {
		dimensions: { contentSpacing },
	} = useTheme();
	const { storeLocator } = useStoreLocatorState();
	const router = useNextRouter();
	const RouteLocal = useLocalization('Routes');
	const [physicalStoreName, setPhysicalStoreName] = useState<string>('');
	const { settings } = useSettings();

	useEffect(() => {
		setPhysicalStoreName(storeLocator.selectedStore?.physicalStoreName ?? '');
	}, [storeLocator.selectedStore]);

	// get the id/partNumber from the first slot
	const partNumber: string = useMemo(
		() => (layout?.slots?.second ? layout?.slots?.second[0]?.id?.toString() : ''),
		[layout?.slots?.second]
	);

	const productDetails = useProductDetails({
		partNumber,
		physicalStoreName,
	});
	const { product } = productDetails;

	useEffect(() => {
		const pageURL = window.location.href;
		const segmentProductViewData: any = {
			brand: product?.manufacturer,
			color_code: '',
			image_url: `https://images.wacoal-america.com/is/image/${S7_COMPANY}${product?.fullImageRaw}`,
			name: product?.name,
			position: 'not set',
			price: product?.productPrice?.max,
			product_id: product?.partNumber,
			sale_price_lower: product?.productPrice?.min,
			sale_price_upper: product?.productPrice?.max,
			sku: product?.partNumber,
			url: pageURL,
			variant: '',
			view_type: 'pdp',
		};
		if (product) {
			setSegmentData('Product Viewed', segmentProductViewData);
		}
	}, [product, typeof window !== 'undefined' && window?.analytics]);
	// Need to keep above dependency as empty as we need to call this component once

	return (
		layout && (
			<ContentProvider value={productDetails}>
				<LayoutSlot slot={layout.slots.header} />
				<Stack
					component="main"
					gap={contentSpacing}
					alignItems="center"
					sx={layoutMainSX(layout.slots.first?.length || 0)}
				>
					<LayoutSlot slot={layout.slots.first} ItemWrap={ItemWrap} />
					<Container>
						<Stack spacing={contentSpacing}>
							<Box>
								<Box sx={productDetailLeftSX}>
									<LayoutSlot slot={layout.slots.second} />
								</Box>
								<Box sx={productDetailRightSX}>
									{product?.partNumber ? (
										<Stack sx={{ paddingTop: { lg: '34px' } }}>
											<LayoutSlot slot={layout.slots.third} />
										</Stack>
									) : (
										''
									)}

									<AccordionSlotsDisplay
										accordionTitle={layout.slots.accordionTitle1}
										accordionContent={layout.slots.accordionContent1}
										defaultExpanded={true}
									/>
									<AccordionSlotsDisplay
										accordionTitle={layout.slots.accordionTitle2}
										accordionContent={layout.slots.accordionContent2}
									/>
									<AccordionSlotsDisplay
										accordionTitle={layout.slots.accordionTitle3}
										accordionContent={layout.slots.accordionContent3}
									/>

									<LayoutSlot slot={layout.slots.fourth} />
								</Box>
							</Box>
						</Stack>
					</Container>
					<LayoutSlot slot={layout.slots.fifth} ItemWrap={ItemWrap} />
					<LayoutSlot slot={layout.slots.footer} />
				</Stack>
			</ContentProvider>
		)
	);
};
